<template>
    <v-container>
        <br>
        <p class="h1 primary--text">Confirmação de Conta</p>
        <br>
        <v-progress-circular
            v-if="loadingConfirmation"
            indeterminate
            color="green"></v-progress-circular>
        <v-alert v-else :type="success ? 'success' : 'error'">
            <p class="white--text">{{ message }}</p>
        </v-alert>
        <v-btn v-if="!$user.isAuthenticated" 
            color="primary" 
            @click="openLogin"><span class="secondary--text">Login</span></v-btn>
        <br>
        <LoginDialog v-if="!$user.isAuthenticated"
        :dialog="loginDialog" 
        @close="loginDialog = false" 
        @success="onSuccess"/>
    </v-container>
</template>
<script>

import { mapActions, mapState } from 'vuex';
import LoginDialog from '../../components/LoginDialog';
export default {
    created() {
        this.confirmSignUp({ 
            token: this.$route.params.token,
            uid: this.$route.params.uid 
        }).then(data => {
            this.success = data.success;
            this.message = data.message;
        }).catch(() => {
            this.success = false;
            this.message = 'Problema no processamento do pedido';
        });
    },
    components: {
        LoginDialog,
    },
    data: () => ({
        success: false,
        message: null,
        loginDialog: false,
        user: null,
    }),
    computed: mapState('auth', [ 'loadingConfirmation']),
    /*watch: {
      '$route': function() {
            this.refreshUser();
        }
    },*/
    methods: {
        ...mapActions('auth', ['confirmSignUp']),
      onSuccess() {
        this.loginDialog = false;
        //this.refreshUser();
        //window.location.reload();
        this.$router.push('/perfil')
      },
      openLogin() {
           this.loginDialog = true;
      },
      refreshUser() {
        if (this.$user.isAuthenticated) {
            return this.user = this.$user.info;
        }
        this.user = null;
      }
    }
   
}
</script>